import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Header } from '../components/header';
import abhyangaImage from '../images/massage_abhyanga.jpg';
import shirotchampiImage from '../images/massage_shirotchampie.jpg';
import cranienImage from '../images/massage_cranien.jpg';
import taimabhyangaImage from '../images/massage_femme_enceinte.jpg';
import cocooningImage from '../images/massage_californien.jpg';
import massagebebeImage from '../images/massage_bebe.jpg';
import thaiAssisImage from '../images/massage_thai_assis.jpg';
import thaiPiedImage from '../images/massage_thai_pied.jpg';
import thaiTraditionnelImage from '../images/massage_thai_tradi.jpg';
import massageJaponaisImage from '../images/massage-japonais-mains.jpg';

import * as stylesLayout from './index.module.css';
import * as stylesMassages from './massages.module.css';

const Massages = ({ data }) => {
  return (
    <div className={`${stylesLayout.main}`}>
      <div>
        <Helmet>
          <meta
            name="description"
            content="Massages à l'huile (Abhyanga, Japonais des mains, Shirotchampi, Taïmabhyanga, Cocooning, Massage bébé), massages habillés  (Crânien, Thaï assis, Thaï des pieds, Thaï traditionnel)"
          />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <Header></Header>
      </div>

      <div className={stylesLayout.mainContent}>
        <h1>Massages d’ici et d’ailleurs</h1>
        <p className={stylesMassages.quote}>
          « Je me suis formée à différentes philosophies de massages mais généralement je personnalise au plus près de
          vos besoins et de vos envies. Avant le soin, je prends toujours un temps pour mieux vous connaitre et étudier
          ce qui vous ferait le plus de bien pour adapter le massage en fonction de votre état physique et psychologique
          »
        </p>

        <p className={stylesMassages.textIntro}>
          Le massage est l’<strong>art du toucher</strong>. Le toucher se développe dès le plus jeune âge. C'est l'un de
          nos 5 sens qui est parfois le moins considéré mais qui est pourtant un sens essentiel au bien être.
          <br />
          Le toucher symbolise la sympathie, la douceur, l’amour. Il aide les thérapeutes à repérer les tensions, les
          maux lors d’une lecture du corps.
        </p>
        <p className={stylesMassages.textIntro}>
          Les massages auront des effets sur vos <strong>systèmes lymphatique, sanguin, musculaire, nerveux</strong> et
          sur vos <strong>énergies</strong>.<br /> Les techniques utilisées seront stimulantes ou douces.
          <br /> Je propose des massages à l’huile ou habillés, qui se pratiquent sur table de massage, chaise
          ergonomique ou tatami.
        </p>
        <h2 id="massage_huile">Massages à l'huile</h2>
        <div className={stylesMassages.massageContainer}>
          <div id="abhyanga" className={stylesMassages.massage}>
            <img src={abhyangaImage} alt="" className={stylesMassages.img} />
            <div className={stylesMassages.text}>
              <strong>Abhyanga</strong> : massage indien alliant des techniques de pressions lentes ou rapides et des
              chassés. . Ce soin permet de libérer les tensions, de recharger le corps en énergie et de relancer les
              systèmes de l’organisme, le corps va se relâcher en profondeur. Il détoxifie et harmonise le corps et
              l’esprit.{' '}
            </div>
          </div>
          <div id="shirotchampi" className={stylesMassages.massage}>
            <img src={shirotchampiImage} alt="" className={stylesMassages.img} />
            <div className={stylesMassages.text}>
              <strong>Shirotchampi </strong> : massage indien du cuir chevelu et haut du dos alliant des frictions,
              mobilisations et lissages. Il permet de détendre au niveau du crâne et de stimuler le cuir chevelu.
            </div>
          </div>
          <div id="taimabhyanga" className={stylesMassages.massage}>
            <img src={taimabhyangaImage} alt="" className={stylesMassages.img} />
            <div className={stylesMassages.text}>
              <strong>Taïmabhyanga (femme enceinte) </strong> : massage indien pour femme enceinte. Par des techniques
              de lissage, de 8, de chassés la femme enceinte se relaxe et les tensions accumulées pendant les différents
              stades de la grossesse se libèrent ou sont soulagées. Attendre le début du 4ème mois de grossesse pour en
              profiter jusqu’au terme.
            </div>
          </div>
          <div id="californien" className={stylesMassages.massage}>
            <img src={cocooningImage} alt="" className={stylesMassages.img} />
            <div className={stylesMassages.text}>
              <strong>Cocooning </strong> : massage doux et enveloppant par des successions de pétrissages, lissages. Il
              a pour effet de détendre tout en rééquilibrant, d’aider à reprendre conscience de son corps, se cocooner
              dans un moment difficile comme un deuil. Les bénéfices sont physiques et psychologiques.
            </div>
          </div>
          <div id="massageBebe" className={stylesMassages.massage}>
            <img src={massagebebeImage} alt="" className={stylesMassages.img} />
            <div className={stylesMassages.text}>
              <strong>Massage du jeune enfant</strong> : C’est un moment où vous allez apprendre à masser votre bébé
              pour renforcer le lien qui vous uni à lui et aussi l’aider à prendre conscience de son corps.
              <br /> Masser votre enfant va l’aider à soulager ses maux comme les coliques, les soucis de sommeil, ses
              tensions, etc… Ce massage se pratique à l’aide d’une huile de massage appropriée et de gestes que vous
              apprendrez dans un endroit chauffé et douillet sous les conseils d’un professionnel. Se pratique dès la
              naissance.
            </div>
          </div>
          <div id="massageJaponais" className={stylesMassages.massage}>
            <img src={massageJaponaisImage} alt="" className={stylesMassages.img} />
            <div className={stylesMassages.text}>
              <strong>Massage Japonais des mains</strong> : Massage des membres supérieurs (épaules, bras, coudes,
              avant-bras et mains) avec une crème ou de l’huile. Ce soin se pratique sur table en 4 phases :
              échauffement, mobilisation, énergétique, final. Par des mouvements lents, enveloppants et des points de
              pressions ce massage va vous inviter à la détente.
            </div>
          </div>
        </div>
        <h2 id="massage_habille">Massages habillé</h2>
        <div className={stylesMassages.massageContainer}>
          <div id="cranien" className={stylesMassages.massage}>
            <img src={cranienImage} alt="" className={stylesMassages.img} />
            <div className={stylesMassages.text}>
              <strong>Crânien</strong> : massage doux de la tête en une succession de lissages, points de pression,
              petits cercles sur les épaules, le crâne et le visage. Il permet une détente dans son ensemble et de
              libérer les tensions au niveau de la tête. Peut soulager les personnes sujettes aux migraines.
            </div>
          </div>
          <div id="thai_assis" className={stylesMassages.massage}>
            <img src={thaiAssisImage} alt="" className={stylesMassages.img} />
            <div className={stylesMassages.text}>
              <strong>Thaï assis</strong> : Ce massage est un enchainement de mouvements alliant des pressions, des
              étirements, des mobilisations et des percussions. Il se pratique sur chaise ergonomique.
              <br /> Il permet de diminuer le stress accumulé essentiellement dans le haut du corps tout en
              l’assouplissant. Ce massage améliore le sommeil et redonne de l’énergie. Il favorise la circulation
              sanguine et lymphatique.
            </div>
          </div>
          <div id="thai_pied" className={stylesMassages.massage}>
            <img src={thaiPiedImage} alt="" className={stylesMassages.img} />
            <div className={stylesMassages.text}>
              <strong>Thaï des pieds</strong> : Il comprend une succession de pressions, et vibrations sur tout le pied
              et la jambe. Pour une partie du massage une huile est utilisée et un « stick » en bois est requis pour
              certaines techniques.
              <br /> Le pied non massé est enveloppé dans une serviette pour que les muscles ne se refroidissent pas et
              se décontractent. Il permet de détendre le corps et l’esprit, d’apporter une sensation de jambes légères
              tout en stimulant la circulation sanguine et énergétique. Il améliore le sommeil, réduit la fatigue et le
              stress et rééquilibre les émotions.
            </div>
          </div>
          <div id="thai_tradi" className={stylesMassages.massage}>
            <img src={thaiTraditionnelImage} alt="" className={stylesMassages.img} />
            <div className={stylesMassages.text}>
              <strong>Thaï traditionnel</strong> : Ce massage est un enchainement de mouvements alliant des pressions,
              des étirements, des mobilisations et des percussions. Il se pratique sur tatami.
              <br /> Il permet de diminuer le stress accumulé essentiellement dans le haut du corps tout en
              l’assouplissant. Ce massage améliore le sommeil et redonne de l’énergie. Il favorise la circulation
              sanguine et lymphatique.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
export default Massages;
